<template>
    <div>
        <label v-if="label" class="app-input-top-label">{{label}}<span v-if="requiredMark" class="required">&nbsp;*</span></label><br v-if="label"/> 
        <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
        <date-picker v-model="timeField"
                    ref="datepicker"
                    @focus="openDatepicker"
                    :id="id_datepicker"
                    :type="type"
                    :format="format"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :minute-step="minuteStep"
                    :value-type="valueType"
                    :disabled-time="disabledTime"
                    @close='closed()'
                    @change="updateValue"
                    @blur="onBlur"
                    :clearable="clearable" 
                    @input-error="onError"
                    :class="{'app-datepicker': true, 'app-timepicker': true, 'app-datepicker-filled': timeField, 'app-datepicker-disabled': disabled, 'app-datepicker-error': cError}"
                        :open.sync="open"
                    >
                    <i slot="icon-calendar" style="margin-top:5px !important" v-if="loading && errors.length == 0" />
        </date-picker>
        <validation-provider v-slot="{ errors}" ref="provider" :rules="cValidatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <input type="hidden" name="date" v-model="timeField">
            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>        
        </validation-provider>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';
import moment from "moment";
import AppMasks from "@core/scripts/AppMasks";
import { time_block } from "@validations";

export default {
    
    components: {
        DatePicker ,
    },

    name: 'AppTime',

    props: {
        id: {type: String},
        value: {},
        name: { type: String },
        placeholder: { type: String, default: "HH:MM" },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label: {type: String},
        additionalInfo: {type: String},
        format: {type: String, default: "HH:mm"},
        valueType: {type: String, default: "HH:mm"},
        minuteStep: {type: Number, default: 5},
        type: {type: String, default: "time"},
        acceptedTime: {type: String, default: null},
        clearable: {type: Boolean, default: false},
        loading: {type: Boolean, default: false},
        requiredMark: {type: Boolean, default: false},
    },

    created() {
        this.id_datepicker = 'datepicker_' + this._uid;
        this.time_mask = this.format.replace(/[DdMmYHhSsw]/g, "#");
        if(this.value != null){
            this.timeField = this.value;
        }
    },

    mounted(){
        document.querySelector('#' + this.id_datepicker).addEventListener('keypress', this.onKeypress)
        document.querySelector('#' + this.id_datepicker).addEventListener('keyup', this.onKeyup)    
        this.is_mounted = true;
    },

    computed: {
        cValidatorRules: {
            get() { 
                var rules = null;
                if(this.validatorRules){
                    rules = this.validatorRules;
                }
                
                if(this.acceptedTime){
                    var range = this.acceptedTime.split('-');

                    rules = rules ? rules + '|disable-time:'+range[0]+','+range[1] : 'disable-time:'+range[0]+','+range[1];
                }
                return rules;
            }
        },

        cError: {
            get(){
                if(this.is_mounted){
                    return this.$refs.provider.errors.length > 0 && this.$refs.provider.errors[0] != '';
                } else {
                    return false;
                }
            }
        }
    },
    
    data() {
        return {
            timeField: null,
            open:false,
            id_datepicker: null,
            time_mask: null,
            app_masks: new AppMasks(),
            is_mounted: false
        };
    },

    watch: {
        timeField(newVal, oldVal) {
            var regex_mask = this.app_masks.getLetterMask(this.time_mask[newVal.length - 1]);

            if(regex_mask != null){
                var res = newVal[newVal.length - 1].match(regex_mask);
                if (!res) {
                    this.timeField = oldVal;
                }
            }

            if(newVal.length < this.format.length && this.time_mask[newVal.length] == ':' && newVal[newVal.length - 1] != ':'){
                this.timeField =  newVal + ':';
            }

            this.$emit("input", this.timeField);
        }
    },


    methods: {
        onBlur(event) {
            if(this.format == "HH:mm"){
                const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
                if(timeRegex.test(this.timeField)){
                    this.$emit('blur', event);
                }else{
                    this.timeField = null;
                }
            }
        },

        async closed(){

            await this.$nextTick();
            this.$refs.provider.validate();
        },

        openDatepicker(event){
            const inputElement = this.$refs.datepicker.$el.querySelector("input");
            if (inputElement){

                setTimeout(() => {
                    inputElement.focus();
                    inputElement.setSelectionRange(0, 0);  
                }, 0);                     
            }                                
        },

        updateValue: function(value, type) {
            
            if(this.timeField != null){
                this.$emit("input", this.timeField);
            }else{
                this.$emit("input", this.timeField);
            }


            if (type === 'minute') {
                this.open = false;
            }
        },

        onKeypress(event){
            let selection = event.target.value.slice(event.target.selectionStart, event.target.selectionEnd);
            if (selection.includes(":")) {
                event.preventDefault();
                return false;
            }

            if(event.target.value.toString().length < this.format.length ){
                var regex_mask = this.app_masks.getLetterMask(this.time_mask[event.target.selectionStart]);

                if(regex_mask != null){
                    var res = event.key.match(regex_mask);
                    if (!res) {
                        event.preventDefault();
                        return false;
                    }
                }
            }else{
                
                if(event.target.value.toString().length <= this.format.length && event.target.selectionStart < this.format.length){

                    if(event.target.selectionStart == 0 && event.target.selectionEnd == this.format.length){
                        event.target.value = event.target.value.slice(event.target.selectionStart, event.target.selectionEnd);
                    }else{
                        event.target.value = event.target.value.slice(0, -(this.format.length - event.target.selectionStart));
                    }
                    
                }else{
                    event.preventDefault();
                    return false;
                }
                
            }
            
        },

        onKeyup(event){
            
            if(event.target.value.toString().length < this.format.length && this.time_mask[event.target.selectionStart] == ':' && event.code != 'Backspace'){
                event.target.value =  event.target.value + ':';
            }

        },

        disabledTime(date){
            if(this.acceptedTime != null){

                var range = this.acceptedTime.split('-');
                var x = moment(range[0], 'HH:mm');
                var y = moment(range[1], 'HH:mm');

                var selected_date = moment(moment(date).format('HH:mm'), 'HH:mm');

                if(selected_date.isBefore(x)){
                    return true;
                }
                if(selected_date.isAfter(y)){
                    return true;
                }
            }
            return false;
        },

        onError(event){
            this.timeField = event;
            this.$emit('input', event);
        }

    },
};
</script>

<style>
</style>