import Vue from 'vue'

// axios
import axios from 'axios';
import router from '../routes/router';
import envVariables from "../util/envVariables";
import AppAlerts from '@core/scripts/AppAlerts';
import translation from "./translations.js";

const getRefreshToken = async () => {
  refresh_in_progress = true;
  await axiosAuthIns.post('/authorization/oauth/token', {
    grant_type: 'refresh_token',
    client_id: 35,
    scope: 'app-portal'
}).then((res)=>{
    let currentTime = new Date().getTime() / 1000; 
    
    access_token_expiries_time = currentTime + res.data.expires_in;
    localStorage.setItem('access_token_expiries_time', access_token_expiries_time);
    refresh_token_expiries_time = currentTime + res.data.refresh_expires_in;
    localStorage.setItem('refresh_token_expiries_time', refresh_token_expiries_time);

  }).catch(() => {
      router.push({name: "Login", query: { redirect: router.fullPath } });
      refresh_result = false;
  }).finally(()=>{
    refresh_in_progress = false;
   
  });
}

  function checkRefreshInProgress() {
    const poll = resolve => {
      if(!refresh_in_progress) resolve();
      else setTimeout(_ => poll(resolve), 10);
    }
  
    return new Promise(poll);
  }

let access_token_expiries_time = null;
let refresh_token_expiries_time = null;
let refresh_in_progress = false;
let refresh_result = true;
let version_warning = false;
let alert_in_progress = false;

var envVariablesObj = new envVariables();

const axiosAuthIns = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_ENDPOINTS_URL,
  headers: {
    Accept: 'application/json',
  }
})


const axiosIns = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_ENDPOINTS_URL,
  headers: {
    Accept: 'application/json',
  }
})

axiosIns.interceptors.request.use(async function (config) {
  config.headers['Start-Time'] = Date.now();
  config.headers.CSRF =  localStorage.getItem('CSRF');
  config.headers.version = envVariablesObj.getVersion();
  await checkRefreshInProgress();

  return config;
});

axiosIns.interceptors.response.use(async function (response) {

  if(response.headers.csrf != null){
    localStorage.setItem('CSRF', response.headers.csrf);
  }
  //Piersza obsługa podczas zwortki
  /*if (response.status === 200 && response.data.message) {
      console.log(response.data.message)
  }
  if (response.status === 201 && response.data.message) {
      console.log(response.data.message)
  }*/

  if(response.config.url == 'authorization/oauth/token'){
    let currentTime = new Date().getTime() / 1000; 

    refresh_result = true;
    
    access_token_expiries_time = currentTime + response.data.expires_in;
    localStorage.setItem('access_token_expiries_time', access_token_expiries_time);
    refresh_token_expiries_time = currentTime + response.data.refresh_expires_in;
    localStorage.setItem('refresh_token_expiries_time', refresh_token_expiries_time);
  }else if(access_token_expiries_time == null){
    access_token_expiries_time = localStorage.getItem('access_token_expiries_time');
  }
  
  let currentTime = new Date().getTime() / 1000; 
  let userData = localStorage.getItem('user-portal');

  let alertClass = new AppAlerts();
  var tranObj = new translation();

   if(access_token_expiries_time - currentTime < 600 && !refresh_in_progress && userData != null){ // less than 10 minutes
       getRefreshToken();
   }
   if(response.config.url != 'authorization/oauth/token'){
        if('dictionary_version' in response.headers && response.headers.dictionary_version != localStorage.getItem('dictionary_version')){
          console.log('bad dictionary');
          localStorage.setItem('dictionary_version', response.headers.dictionary_version);

          Vue.prototype.translations.updateDictionary();
        }


        if( 'version' in response.headers && response.headers.version != envVariablesObj.getVersion()){   
          
          if(!alert_in_progress && router.currentRoute.name != 'Login'){
            alert_in_progress = true;
            try{
              alertClass.openAlertWarning(tranObj.trans('not-valid-version',281), true).then( async ()=> {
                alert_in_progress = false;
                localStorage.clear();
                router.push({ name: 'Login' });
                window.location.reload(true);
              });  
            }catch{
              alert_in_progress = false;
            } 
          }
        }
    }

  return response
},async function (error) {

  
  //Obsługa błędów
  if (error.response) {

    if (error.response.status === 404) {

      router.push({ name: "404-error-page" });

    }else if(error.response.status === 500){
      
      router.push({name: "500-error-page"});

    }else if(error.response.status === 452){//File not found - nasz customy status code pula od 452 - 499 jest pusta

      
    }else if(error.response.status === 403){
      router.push({name: "403-error-page"});
    }
    //Obsługa 401, brakuje obsługi refresh token dla oauth2, obecnie wykorzystujemy personal token,
    //który nie ma resfresh token
    if ((error.response.status === 401)) {
        await checkRefreshInProgress();
        let currentTime = new Date().getTime() / 1000; 
        if(access_token_expiries_time - currentTime < 0 || error.config.repeat === true){
          await getRefreshToken();
        }
        if(refresh_result){

            error.config.repeat = true;
            return axiosIns.request(error.config).then();
         
        }

    }else if(error.response.status === 403){
      router.push({name: "Login", query: { redirect: router.fullPath } });
    }else{
      console.log('error');
      return Promise.reject(error)
    }

  }
  
})

Vue.prototype.$http = axiosIns
export default axiosIns
