<template>
    <div> 
        
            
            <!-- <label v-if="label" class="app-input-top-label">{{label}}</label> -->
            <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
            <b-row v-if="is_all" class="mb-1">
                <b-col cols="4" class="d-flex align-items-center" v-if="labelPosition == 'left'">
                        <div class="col-form-label app-input-label">{{label}}<span v-if="requiredMark" class="required">&nbsp;*</span></div>
                </b-col>
                 <b-col :cols="labelPosition == 'left' ? 8 : 12" class="app-local-padding" :class="{'pl-2': labelPosition == 'left'}">
                        <label v-if="label && labelPosition == 'top'" class="app-input-top-label">{{label}}<span v-if="requiredMark" class="required">&nbsp;*</span></label>
                        <v-select v-model="all_value" label="name" :loading="loading" :options="options" @input="changeValue" :clearable="clearable" :dropdownOverlay="true" :class="{'app-select-filled': all_value, 'app-select-error': cError}" :disabled="disabled">
                            <template v-slot:selected-option="option">  
                                <span style="width:245px" v-overflow-tooltip>{{option.name }}</span>
                            </template>
                            <template v-slot:option="option">  
                                <span v-overflow-tooltip>{{ option.name }}</span>
                            </template>
                            <template #spinner="{ loading }">
                                <div v-if="loading" class="d-flex justify-content-center align-items-center text-primary">
                                    <b-spinner class="align-middle app-spinner-size"></b-spinner>
                                </div>
                            </template> 
                        </v-select>
                </b-col>
            </b-row>
        
            <div v-else>
                <b-row v-for="item, i  in selected_items" :key="i" :class="i != 0 || getLabel(1) == null || labelPosition == 'left' ? 'mb-1' : ''">
                    <b-col cols="4" class="d-flex align-items-center" v-if="label && labelPosition == 'left'">
                        <div class="col-form-label app-input-label">{{getLabel(i)}}<span v-if="requiredMark" class="required">&nbsp;*</span></div>
                    </b-col>
                    <b-col :cols="label && labelPosition == 'left' ? 8 : 12" class="app-local-padding" :class="{'pl-2': labelPosition == 'left'}" v-if="showMultiOptions == true || (showMultiOptions == false && i == 0)">
                        <b-row>
                            <b-col cols="12">
                                <label v-if="label && labelPosition == 'top' && getLabel(i) != null" class="app-input-top-label">{{getLabel(i)}}<span v-if="requiredMark && i < 1" class="required">&nbsp;*</span></label>
                                <div class="d-flex w-100 justify-content-between">
                                    <v-select v-model="selected_items[i]" label="name" :loading="loading" :options="getOptions(selected_items[i].value, i)" @input="changeValue(selected_items[i], i)" :clearable="clearable && i == 0 && selected_items[i].value != null && selected_items.length == 1" style="flex-grow: 1; width:235px" :key="refresh" :class="{'app-select-filled': selected_items && selected_items[i].value, 'app-select-error': i == 0 && cError}" :disabled="disabled">
                                        <template v-slot:selected-option="option">  
                                            <span v-bind:class="{ multi: selected_items.length > 1, single: selected_items.length <= 1 }" v-overflow-tooltip>{{ option.name }}</span>
                                        </template>
                                        <template v-slot:option="option">  
                                            <span v-overflow-tooltip>{{ option.name }}</span>
                                        </template>
                                        <template #spinner="{ loading }">
                                        <div v-if="loading" class="d-flex justify-content-center align-items-center text-primary">
                                                <b-spinner class="align-middle app-spinner-size"></b-spinner>
                                            </div>
                                        </template> 
                                    </v-select>

                                    <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':disabled}" v-if="selected_items.length > 1" @click="remove(item)" :disabled="disabled">
                                        <b-icon icon="dash" class="list-button-icon"></b-icon>
                                    </app-button>
                                </div>

                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>

        <validation-provider ref="provider" v-slot="{ errors}" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <b-row>
                <b-col v-if="labelPosition == 'left'" cols="4"></b-col>
                <b-col :cols="labelPosition == 'left' ? 4 : 6" :class="{'pl-1' : labelPosition == 'left'}">
                    <input type="hidden" v-model="cValidateValue" />
                    <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error" v-overflow-tooltip>{{ errors[0] }}</small> 
                </b-col>
                <b-col end class='d-flex justify-content-end pl-0' :class="{'app-local-margin': addButtonLabel.length > 19}" v-if="selected_items.length < (items.length) && showMultiOptions != false">
                    <app-button type="cancel" @click="add" class="p-0 app-local-text" :class="{'app-local-text-disabled' : disabled}" :disabled="disabled"><b-icon icon="plus-circle" class="mr-1" /> <span> {{addButtonLabel}} </span></app-button>
                </b-col>
            </b-row>
        </validation-provider>
    </div>
</template>
<script>

import vSelect from "vue-select";
import axios from "@axios";

export default {

    name: "AppMultiSelect",

    props: {
        value: { type: Array, default: () => [] },
        type: { type: String },
        args: { type: Object },
        refreshIndex: { type: Number, default: 0 },
        multiple: { type: Boolean },
        disabled: { type: Boolean },
        showMultiOptions: { type: Boolean, default: true },
        clearable: { type: Boolean, default: true },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label: {type: String},
        additionalLabel: {type: String},
        additionalInfo: {type: String},
        addButtonLabel: {type: String, default: 'Voeg een record toe'},
        isAllOption: { type: Boolean, default: true },
        labelPosition: { type: String, default: 'left' }, // left, top
        requiredMark: { type: Boolean, default: false },
    },

    components: {
        vSelect
    },

    computed: {
        cSelectedIds:{
            get() { return this.value },
            set(value) { this.$emit('update:value', value ) }
        },

        cValidateValue: {
            get(){
                var result = 0;
                this.selected_items.forEach(element => {
                    if(element.value != null){
                        result++;
                    }
                });

                return result ? true : null;
            }
        },

        cError: {
            get(){
                if(this.is_mounted){
                    return this.$refs.provider.errors.length > 0 && this.$refs.provider.errors[0] != '';
                } else {
                    return false;
                }
            }
        }
    },

    created(){
        
        if(!this.disabled){
            this.getData();
        }
        

        if(this.value.length > 0 && this.value.length == (this.items.length)){
            this.is_all = true;
        }
    },

    mounted(){
        this.is_mounted = true;
    },

    watch: {
      "selected_items": {
            handler: function(val) {
                this.refresh++;    
            },
            deep: true,
        },

        "$refs.provider.errors":{
            handler: function(value){
            },
            deep: true
        },

        "refreshIndex": {
            handler: function(val) {
                this.getData();
            },
        },
    },

    data(){
        return {
            loading: false,
            items: [],
            options: [],
            is_all: false,
            all_value: {value: 0, name: 'Alle'},
            selected_items: [{value: null, name: null}],
            refresh:0,
            is_mounted: false
        }
    },

    methods: {
        add(){
            this.selected_items.push({value: null, name: null});
        },

        remove(item){
            var index = this.selected_items.indexOf(item);
            this.selected_items.splice(index, 1);

            if(item.value != null){
                index = this.cSelectedIds.indexOf(item.value);
                this.cSelectedIds.splice(index, 1);
            }

            if(this.$refs.provider.errors.length > 0 && this.$refs.provider.errors[0] != '') this.error = true;
            else this.error = false;
        },

        getData() {
            this.loading = true;
            axios
                .post("core/select-item/getList", {
                    type: this.type,
                    parameters: JSON.stringify(this.args),
                })
                .then((res) => {
                    this.items = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                    this.setData();
                    this.$emit('finishLoad', 1);                
                });
        },

        setData(){
            if(this.value.length > 0){
                this.selected_items = this.items.filter((element) => {
                    if(this.value.includes(element.value)){
                        return element;
                    }
                });
            }

            this.options = this.isAllOption ?  [this.all_value] : [];
            Array.prototype.push.apply(this.options, this.items);

                if(this.type == 'getDepartmentsFunctions' && this.selected_items.length > 0 && this.value.length > 0){
                    if(this.selected_items[0].value != this.value[0]){
                        this.selected_items.unshift(this.selected_items.splice(this.selected_items.findIndex(item => item.value === this.value[0]), 1)[0]);
                    }

                    this.cSelectedIds.length = 1;
                    this.selected_items.forEach((element, index) => {
                        if(index > 0){
                            this.cSelectedIds.push(element.value);
                        }
                    });
                }
        },

        async changeValue(event, i){

            if(event == null){
                this.selected_items = [{value: null, name: null}];
                this.cSelectedIds.splice(0, this.cSelectedIds.length);
            } else{

                if(event.value == 0){
                    this.is_all = true;
                    this.all_value = {value: 0, name: 'Alle'};
                    this.cSelectedIds.splice(0, this.cSelectedIds.length);
                    this.items.forEach(element => {
                        this.cSelectedIds.push(element.value);
                    });
                    this.selected_items = this.items.map(element => element);
                } else {
                    if(this.is_all){
                        this.selected_items = [event];
                        this.cSelectedIds.splice(0, this.cSelectedIds.length);
                        this.cSelectedIds.push(event.value);
                        this.is_all = false;
                    }
                    if(event.value != null){
                        this.cSelectedIds.splice(0, this.cSelectedIds.length);
                        this.selected_items.filter((item) => item.value != null).forEach((element) => {
                            this.cSelectedIds.push(element.value);
                        });
                    
                    }
                }
            }

            await this.$nextTick(); 
            if(this.type != 'getDepartmentsFunctions'){
                this.$refs.provider.validate();
            }
        },

        getOptions(item, index){
            if(this.type == 'getDepartmentsFunctions' && index == 0){
                return this.options;
            }

            if(this.options.length > 0){
            return this.options.filter((element) => {
                if(element.value == 0){
                    if(this.selected_items.length == 1){
                        return element;
                    }
                }
                else if(element.value == item){
                    return element;
                } else {
                    if(item != element.value && !this.selected_items.includes(element)) {
                        return element;
                    }
                }
            });
            } else {
                return this.options;
            }
            this.refresh++;
        },
        getLabel(i){
            if(i == 0) return this.label;
            if(this.additionalLabel && i == 1) return this.additionalLabel;
            return null;
        }
    }
    
}
</script>
<style scoped>
.single{
    width:245px !important;
}
.multi{
    width:225px !important;
}

.app-local-text{
    color: #7f7f7f !important;
}

.app-local-text:hover{
    color: black !important;
    opacity: 0.8;
}

.app-local-text:focus{
    color: black !important;
    opacity: 0.8;
}

.app-local-text-disabled{
    pointer-events: none !important;
    color: #7f7f7f !important;
    opacity: 0.65 !important;
}

.app-local-text-disabled:hover{
    cursor: default !important;
}


.app-local-button{
    opacity: 1 !important;
    border: solid 1px #D0D5DD !important;
    border-radius: 0.25rem !important;
    line-height: 1rem !important;
    font-size:  1rem !important;
    color: #066791 !important;
    max-height: 31.3px !important;
    min-height: 31.3px !important;
}
.app-local-button:hover{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button:focus{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button-disabled:hover{
    border: solid 1px #D0D5DD !important;
    box-shadow: none;
}
.list-button-icon{
    color: #066791 !important;
}

.app-local-margin{
    margin-left: -3px;
}
</style>