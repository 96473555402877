<template>
    <div > 
        <b-link :id="'popover' +  _uid" class="ml-1" style="color: #98A2B3 ; cursor:pointer;outline: none !important;">
            <b-icon :icon="filteringObject.values.length > 0 ? 'funnel-fill' : 'funnel'" />
        </b-link>
        
        <b-popover :target="'popover' + _uid" ref="popover" triggers="focus"  no-fade placement="bottomleft"  @hide="closePopover" @show="openPopover" boundary="window">
            <template #title>
                {{label}}
            </template>
           
           <div>
                               
                    <b-card no-body class="mb-2">
                        <b-card-body >
                            <div class="app-fixed-search d-flex align-items-center">
                                <div class="d-flex align-content-center justify-content-between w-100">
                                    <b-input-group class="input-group-merge" >
                                    
                                        <b-input-group-prepend is-text>
                                            <b-icon icon="search" class="text-muted" v-if="!loading"></b-icon>      
                                            <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                                        </b-input-group-prepend >

                                        <b-form-input autofocus class="pl-2"  v-model="search" :placeholder="trans('search',280)" style="border:none !important;"/>

                                        <b-input-group-append is-text v-if="search.length > 0">
                                        <b-icon icon="x-circle-fill" class="text-muted" style="cursor: pointer; outline: none !important;" @click="search = ''"></b-icon>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <hr class="app-search-modal-separator">
                                <b-list-group id="items-list" flush style="max-height:300px; min-width:124px; overflow-y:auto;">
                                    <b-list-group-item class="d-flex  align-items-center" @keyup.enter="trigger" action v-for="item in filteredList" :key="item.value" :value="item" tabindex="0" style="cursor:pointer; padding:4px 4px; outline: none !important;" @click.prevent="selectItem(item)">
                                        <!-- <b-form-checkbox tabindex="0" v-model="item.selected" value="1" unchecked-value="0" size="sm" @change="refreshCounters" :id="'check' + index" :class="item.selected == 1 ? 'custom-checkbox-checked' : ''">
                                            <span class="app-documents-item">{{item.name}}</span>
                                        </b-form-checkbox> -->
                                        <div class="d-flex">
                                            <div class="local-check mr-2" :class="{'local-check-selected': item.selected === '1'}"></div><span class="app-documents-item">{{item.name}}</span>
                                        </div>
                                    </b-list-group-item>
                                </b-list-group>
                        </b-card-body>
                    </b-card> 
                    <b-row class="align-items-center">
                        <b-col cols="auto" class="mr-auto">
                            <b-link v-if="checkedCount > 0" class="ml-1" style="color: #98A2B3; cursor:pointer;" @click="clearFilter">{{trans('remove',282)}} ({{ this.checkedCount }})</b-link>
                        </b-col>
                        <b-col cols="auto">
                            <app-button type="primary" ref="setFilters" @click="setFilter" >{{trans('apply',282)}}</app-button>
                        </b-col>
                    </b-row>
            </div>
            
           </b-popover>
           
    </div>  
</template>
<script>

import axios from "@axios";
export default {
    props: ["label", "filteringObject", "fieldName"],

    computed: {    
        filteredList() {
            // return this.items.filter(item => {
            //     return item.name.toLowerCase().includes(this.search.toLowerCase())
            // })   
            return this.visible_list;      
        },

        cFilteringObject: {
            get() { return this.filteringObject },
            set(value) { this.$emit('update:filteringObject', value); }
        }
    },

    watch:{
        "search": {
            handler: function(val){
                this.setBegin();
            }
        },
    },


    data() {
        return {
            loading: false,
            search: "",
            items: this.filteringObject.customList ? this.filteringObject.customList:[],
            checkedCount:0,
            currentItem:-1,
            items_count:0,
            topPos: 0,
            page_number: 1,
            prev_page: 1,
            page_size: 50,
            list_element: null,
            listHeight: 0,
            visible_list: [],
        };
    },

    mounted () {
        document.addEventListener("keydown", this.nextItem);
    },
    
    methods: {

        trigger () {
    	    this.setFilter();
        },

        nextItem() {        
            if(this.items.length > 0){
                
                //block scrolling on arrows
                window.addEventListener("keydown", function(e) {
                    if(["ArrowUp","ArrowDown"].indexOf(e.code) > -1) {
                        e.preventDefault();
                    }
                }, false); 

                //change focus on checkboxes on arrows
                if (event.keyCode == 38 && this.currentItem > 0) {
                    this.currentItem--
                    document.getElementById("check"+this.currentItem).focus();
                } else if (event.keyCode == 40 && this.currentItem < this.items_count - 1) {
                    this.currentItem++
                    document.getElementById("check"+this.currentItem).focus();
                }
            }
        },

        selectItem(item) {          
            
           var index = this.items.indexOf(item);
           var visible_index = this.visible_list.indexOf(item);
           if (item.selected === '1') {
                item.selected = '0';
            } else {
                item.selected = '1';
            }

            this.$set(this.items, index, item);
            this.$set(this.visible_list, visible_index, item);
        },

         refreshCounters() {           
             this.checkedCount = this.items.filter(item => {return item.selected === "1"}).length;
         },
         getData() {
             
            this.loading = true;
            axios
                .post("core/select-item/getList", {
                    type: this.filteringObject.function,
                    parameters: JSON.stringify(this.filteringObject.parameters),
                })
                .then((res) => {
                    this.items = res.data;
                    this.currentItem = -1;
                    this.items_count = this.items.length;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                   
                     this.items.forEach((item, index) => {
                        var exist = this.filteringObject.values.filter(element => {return element === item.value}).length;
                    
                        if (exist > 0) {
                            
                            item.selected = "1";
                        } else {
                            item.selected = "0";
                        }
                    });

                    this.refreshCounters();

                   this.loading = false;

                   this.list_element = document.getElementById('items-list');
                   this.topPos = this.list_element.scrollTop;

                   this.visible_list = this.items.filter(item => {
                        return item.name.toLowerCase().includes(this.search.toLowerCase())
                    }).slice((this.page_number - 1) * this.page_size, this.page_size* this.page_number)  

                   this.list_element.addEventListener('scroll', ()=> {
                        this.listHeight = this.list_element.scrollHeight;
                        this.topPos = this.list_element.scrollTop;
                        if(this.topPos > Math.floor(this.listHeight * 0.75) && (this.visible_list.length < this.items_count)){
                            this.page_number ++;
                        }
                        
                        if(this.page_number > this.prev_page){

                                var a = this.items.filter(item => {
                                    return item.name.toLowerCase().includes(this.search.toLowerCase())
                                    }).slice((this.page_number - 1) * this.page_size, this.page_size* this.page_number)

                                if(a.length > 0){
                                    this.visible_list = this.visible_list.concat(a);
                                    this.prev_page = this.page_number;
                                }
                        }
                        
                   })
                });
        },
       
        async openPopover() {
            
            if(this.filteringObject.getList == true || this.filteringObject.refreshList == true){//ponowne pobieranie w przypadku listy shift i assignment
                this.items = [];
                this.getData();
                this.cFilteringObject.refreshList = false;
                this.checkedCount = 0;
            }else if (this.items.length > 0) {
                if (this.filteringObject.values.length == 0) {
                    this.items.forEach((item, index) => {
                        item.selected = "0" ;
                    }); 
                    this.refreshCounters();          
                } else {
                    this.items.forEach((item, index) => {
                        if(this.filteringObject.values.includes(item.value)){
                            item.selected = "1";
                        } else {
                            item.selected = "0";
                        }
                        
                    }); 
                    this.refreshCounters();   
                }

                this.setBegin();
                await this.$nextTick();
                this.list_element = document.getElementById('items-list');
                this.list_element.addEventListener('scroll', ()=> {
                        this.listHeight = this.list_element.scrollHeight;
                        this.topPos = this.list_element.scrollTop;
                        if(this.topPos > Math.floor(this.listHeight * 0.75) && (this.visible_list.length < this.items_count)){
                            this.page_number ++;
                        }
                        
                        if(this.page_number > this.prev_page){

                                var a = this.items.filter(item => {
                                    return item.name.toLowerCase().includes(this.search.toLowerCase())
                                    }).slice((this.page_number - 1) * this.page_size, this.page_size* this.page_number)

                                if(a.length > 0){
                                    this.visible_list = this.visible_list.concat(a);
                                    this.prev_page = this.page_number;
                                }
                        }
                        
                   })
            } else {
                this.getData();
            }  
                

        },

        closePopover() {
            this.refreshCounters();
            this.visible_list = [];
        },

        clearFilter() {
            var nFilteringObject = this.filteringObject;

            this.items.forEach((item, index) => {
                item.selected = "0" ;
            });          
            
            this.checkChanged();

            nFilteringObject.values = [];
            this.refreshCounters();
            this.$refs.popover.$emit('close');
        },

        setFilter() {
            var nFilteringObject = this.filteringObject;
            var ids = [];
            var selectedElements = this.items.filter(item => {return item.selected === "1"});
            selectedElements.forEach((item, index) => {
                ids.push(item.value);
            });  

            this.checkChanged();
          

            nFilteringObject.values = ids;
            this.refreshCounters();
            this.$refs.popover.$emit('close');
        },

        checkChanged() {
            var nFilteringObject = this.filteringObject;
            var ids = [];
            var selectedElements = this.items.filter(item => {return item.selected === "1"});
            selectedElements.forEach((item, index) => {
                ids.push(item.value);
            });  
            if (JSON.stringify(this.filteringObject.values) !== JSON.stringify(ids)) {
                 nFilteringObject.changed++;
            }
        },

        setBegin(){
            this.page_number = 1;
                this.prev_page = 1;
                this.list_element.scrollTop = 0;
                this.topPos = 0;

                this.visible_list = this.items.filter(item => {
                        return item.name.toLowerCase().includes(this.search.toLowerCase())
                    }).slice((this.page_number - 1) * this.page_size, this.page_size* this.page_number) 
        }


    },

};
</script>

<style scoped>
.app-spinner-size {
    max-width:20px;
    max-height:20px;
}

.popover .arrow {
    display: none !important;
}

.custom-control:focus {
    outline: none !important;
}

.input-group-text {
    border: none;
}

.card-body {
    padding: 4px;
}
.input-group-text .b-icon{
    overflow: visible;
}


.container {
             width: 100%;
             height: 100%;
             background-color: green;
             display: flex;
             justify-content: center;
             align-items: center;
         }
         .child-div {
             height: 100%;
             background-color: red;
             margin: 0 20px;
         }

.local-check{
    min-width: 20px !important;
    min-height: 20px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    border: 1px solid #667085;
    border-radius: 4px;
}

.local-check:hover{
    border-color: #066791;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) !important;
}

.local-check-selected{
   box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) !important;
   background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23066791' d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' /%3E%3C/svg%3E");
   background-size: 15px;
   padding-top: 1.2px;
   padding-left: 1.8px;
   background-origin: content-box;
   transition: none !important;
   background-color: #dcf0f9;
   border-color: #066791 !important;   
}
</style>