export default class envVariables {


    getVersion(){
        return '031.01';
    }

    getEnvironment(){
        if (process.env.NODE_ENV !== 'production') {
            return 1;
        } else {
            //Test
            //return 1;

            //Pre production
            // return 2;

            //Production
             return 3;
        }
    }
    
}