import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';
import Page404 from '@/views/404Page.vue';
import Page403 from '@/views/403Page.vue';
import Page500 from '@/views/500Page.vue';

const Login = () => import( '@/views/Login.vue');
const ResetPassword = () => import( '@/views/ResetPassword/ResetPassword.vue');
const Register = () => import( '@/views/Register.vue');
const ForgetPassword = () => import( '@/views/ForgetPassword/ForgetPassword.vue');
const Dashboard = () => import('@app/Dashboard/Dashboard.vue');

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [    
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/reset-password/:token',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/register/:token',
      name: 'Register',
      component: Register
    },
    {
      path: '/forget-password',
      name: 'ForgetPassword',
      component: ForgetPassword
    },
    { 
      path: '*',
      name: '404-error-page',
      component: Page404
    }
  ]
};


const routes = [
  
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Dashboard',
  },

  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {name_nl: 'dashboard'}
      },
      {
        path: '/shifts',
        name: 'planning-offers-shifts',
        component: () => import('@app/Planning/Offers/Shifts/OffersShifts.vue'),
        meta: {name_nl: 'diensten'}
      },

      {
        path: '/shifts/:id_person_data_main',
        name: 'planning-offers-shifts',
        component: () => import('@app/Planning/Offers/Shifts/OffersShifts.vue'),
        meta: {name_nl: 'diensten'}
      },

      {
        path: '/planning/offers/shifts/add',
        name: 'planning-offers-shifts-add',
        component: () => import('@app/Planning/Offers/Shifts/Add/OffersShiftsAdd.vue'),
        meta: {name_nl: 'diensten'}
      },

      {
        path: '/planning/offers/shifts/add/:id_shift_data_main',
        name: 'planning-offers-shifts-add-copy',
        component: () => import('@app/Planning/Offers/Shifts/Add/OffersShiftsAdd.vue'),
        meta: {name_nl: 'dienst aanmaken'}
      },

      {
        path: '/hours',
        name: 'planning-offers-hours',
        component: () => import('@app/Planning/Offers/Hours/OffersHours.vue'),
        meta: {name_nl: 'uren'}
      },

      {
        path: '/planning/offers/shifts/add-person',
        name: 'planning-shifts-add-person',
        component: () => import('@app/Planning/Offers/Shifts/PersonAdd/OffersShiftsListPersonAdd.vue'),
        meta: {name_nl: 'rt-flexworker-sign-up'}
      },

      {
        path: '/planning/offers/shifts/add-bulk',
        name: 'planning-offers-shifts-add-bulk',
        component: () => import('@app/Planning/Offers/Shifts/Add/OffersShiftsAddBulk.vue'),
        meta: {name_nl: 'rt-create-service-bulk',}
      },

      {
        path: '/users',
        name: 'planning-offers-users',
        component: () => import('@app/Planning/Offers/Users/Users.vue'),
        meta: {name_nl: 'Gebruikers'}
      },

      {
        path: '/clients/management',
        name: 'clients-management',
        component: () => import('@app/Clients/Management/ClientsManagement.vue'),
        meta: {name_nl: 'Management'}
      },

      {
        path: '/clients/settings',
        name: 'clients-settings',
        component: () => import('@app/Clients/Settings/ClientsSettings.vue'),
        meta: {name_nl: 'Settings'}
      },

      {
        path: '/clients/documents',
        name: 'documents-list',
        component: () => import('@app/Clients/Documents/ClientsDocumentsList.vue'),
        meta: {name_nl: 'Documenten'}
      },

      {
        path: '/dev-components',
        name: 'development-components',
        component: () => import('@app/DevComponents/DevComponentsList.vue'),
        meta: {name_nl: 'Development Components'}
      },

      {
        path: '/assignments',
        name: 'planning-offers-assignments',
        component: () => import('@app/Planning/Offers/Assignments/OffersAssignments.vue'),
        meta: {name_nl: 'opdrachten'}
      }
    ]
  }
  ,
  
   authPages
];

export default routes;
