<template>
    <app-base-nav container-classes="container-fluid" class="navbar-top border-bottom navbar-expand app-navbar" :class="{ 'app-navbar-margin': envVariables.getEnvironment() == 2 }">
        
        <b-navbar-nav class=" ml-md-auto">

        </b-navbar-nav>

        <b-navbar-nav class="align-items-center ml-auto ml-md-4" v-bind:class="{ 'mt-4': isScrolled && envVariables.getEnvironment() == 2 }">
            <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
            <li class="nav-item d-xl-none">
                <!-- Sidenav toggler -->
                <div class="pr-3 sidenav-toggler" :class="{ active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light' }" @click="toggleSidebar">
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                    </div>
                </div>
            </li>
            <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
            <li class="nav-item d-sm-none">
                <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
                    <i class="ni ni-zoom-split-in"></i>
                </a>
            </li>
           
             <b-dropdown variant="link" no-caret right toggle-class="p-0">
                <template #button-content>
                    <div class="d-flex align-items-center">
                        <div class="mr-3 text-right">
                            <p class="mb-0 app-local-user-name">{{user_name}}</p>
                            <span class="app-local-user-data">{{client_name}}</span>
                        </div>
                        <div class="b-avatar avatar avatar-sm rounded-circle badge-third rounded-circle">
                            {{userAvatarText}}
                        </div>
                    </div>
                    
                </template>
                <b-dropdown-item @click="openSettings" class="app-local-dropdown-width">
                    <b-row class="d-flex align-items-center">
                        <b-col cols="2" class="align-middle">
                            <div class="b-avatar avatar avatar-sm rounded-circle badge-third rounded-circle font-weight-bolder">
                                {{userAvatarText}}
                            </div>
                        </b-col>
                        <b-col cols="10">
                            <div class="mb-0 app-local-height">
                                <p class="font-weight-bolder mb-0">{{ user_name }}</p>
                                <span class="app-local-user-status" v-overflow-tooltip>{{ user_email }}</span><br/>
                                <!--<span class="app-local-user-status" v-if="id_user_const_role == 5">Admin</span>
                                <span class="app-local-user-status" v-if="id_user_const_role == 6">User</span>-->
                            </div>
                        </b-col>
                    </b-row>
                </b-dropdown-item>
                <b-dropdown-divider />
                <!--<b-dropdown-item @click="openDev">{{trans('dev-components',280)}}</b-dropdown-item>-->
                <b-dropdown-item-button @click="openManual">{{trans('user-manual', 283)}}</b-dropdown-item-button>
                <b-dropdown-item @click="openProfile">{{trans('auth-change-password',277)}}</b-dropdown-item>
                <b-dropdown-item @click="logout">{{trans('log-out',282)}}</b-dropdown-item>
            </b-dropdown>
        </b-navbar-nav>
    </app-base-nav>
   
</template>
<script>

import AppBaseNav from '../../@core/components/AppBaseNav.vue';
import axios from "@axios";
import envVariables from "/src/util/envVariables.js";

export default {
    components: {
        AppBaseNav
    },
    props: {
        type: {
            type: String,
            default: "default", // default|light
            description: "Look of the dashboard navbar. Default (Green) or light (gray)",
        },
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
    },
    data() {
        return {
            userAvatarText: JSON.parse(localStorage.getItem('user-portal')).shortcut_name,
            user_name: JSON.parse(localStorage.getItem('user-portal')).user_name,
            user_email: JSON.parse(localStorage.getItem('user-portal')).email,
            user_id: JSON.parse(localStorage.getItem('user-portal')).id,
            id_user_const_role: JSON.parse(localStorage.getItem('user-portal')).id_user_const_role,
            client_name: JSON.parse(localStorage.getItem('user-portal')).client_name,
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: "",
            display_menu: true,
            isScrolled: false,
            envVariables: new envVariables()
        };
    },
    created(){
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        openDev(){
            this.$router.push({ name: "development-components" });
        },

        logout() {
            axios.post(
              "authorization/logout", {
                                          
                  })
              .then((response) => {
                  localStorage.removeItem("user-portal");
                  this.$router.push({ name: "Login" });
              })
              .catch((error) => {
                      console.error(`error during request: ${error}`);
              })
          
        },
        
        openSettings(){
            this.$router.push({ name: "clients-settings" });
        },

        openProfile(){
            this.$router.push({ name: "clients-management"});
        },

        toggleSidebar() {
            //console.log(JSON.parse(localStorage.getItem('user-portal')));
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            //console.log(JSON.parse(localStorage.getItem('user-portal')));
            this.$sidebar.displaySidebar(false);
        },
        openManual(){
            window.open(localStorage.getItem('user_manual_link'), '_blank');
        },

        handleScroll() {
            this.isScrolled = window.scrollY > 0;
        },
    },
};
</script>
<style scoped>
.app-local-user-status {
    font-size: smaller;
    color: gray;
}

p{
    line-height:1.1 !important;
}

.app-local-dropdown-width{
    width:270px !important
}

.app-local-user-name{
    font-weight: 600;
    font-size: 13px;
}

.app-local-user-data{
    font-weight: 300;
    font-size: 11px;
}

.app-local-height{
    max-height:37px;
    min-height:37px
}

@media (max-width: 660px){
    .auto-hide {
            display: none !important;
    }
    .auto-with{
        width: 100%;
        margin-right: 0px !important;
    }
}

.app-navbar-margin {
    transition: all 0.2s ease;
}

.navbar-nav {
    transition: margin 0.2s ease;
}
</style>