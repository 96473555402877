<template>
    <div v-bind:class="{'app-local-calendar-icon': value && clearable, 'app-local-clear-icon': value && clearable}">
        <label v-if="label" class="app-input-top-label">{{label}}</label><br v-if="label"/>
        <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
        
        <date-picker v-model="weekField"
                        :type="type"
                        :format="format"
                        :value-type="valueType"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :formatter="format === 'YYYY-w' ? momentFormat : null"
                        :class="{'app-datepicker': true, 'app-datepicker-filled': weekField, 'app-datepicker-disabled': disabled, 'app-datepicker-error': cError}"


                        @close='closed()'
                        @change="updateValue"
                        @blur="onBlur" 
                        :clearable="clearable"
                    >
            <i slot="icon-calendar" v-if="loading && errors.length == 0" />

        </date-picker>
        <validation-provider v-slot="{ errors}" ref="provider" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <input type="hidden" name="date" v-model="weekField">
            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>        
        </validation-provider>
    </div>
</template>
<script>
import { defaultFormat } from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';
import moment from "moment";

export default {
    
    components: {
        DatePicker ,
    },

    name: 'AppWeek',

    props: {
        id: {type: String},
        value: {},
        name: { type: String },
        placeholder: { type: String, default: "Week" },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label: {type: String},
        additionalInfo: {type: String},
        format: {type: String, default: "YYYY-w"},
        valueType: {type: String, default: "YYYY-w"},
        type: {type: String, default: "week"},
        loading: {type: Boolean, default: false},
        clearable: {type: Boolean, default: false}
    },

    created() {
        if(this.value != null){
            this.weekField = this.value;
        }
    },

    mounted(){ 
        this.is_mounted = true;
    },

    computed: {
        cError: {
            get(){
                if(this.is_mounted){
                    return this.$refs.provider.errors.length > 0 && this.$refs.provider.errors[0] != '';
                } else {
                    return false;
                }
            }
        }
    },

    watch:{
        value:{
            handler: function(val){
                this.weekField = this.value;
            }
        }
    },
    
    data() {
        return {
            weekField: null,
            is_mounted: false,
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('GGGG-W') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'GGGG-W').toDate() : null
                },
                getWeek: (date) => {
                    return moment(date).isoWeek()
                }
            }
        };
    },


    methods: {
        onBlur(event) {
            this.$emit('blur', event.target.value);
        },

        async closed(){

            await this.$nextTick();
            this.$refs.provider.validate();
        },

        updateValue: function(value) {
            if(this.weekField != null){
                this.$emit("input", this.weekField);
            }else{
                this.$emit("input", this.weekField);
            }
        },
    },
};
</script>

<style>
.app-local-calendar-icon .mx-icon-calendar{
    display:none;
}

.app-local-clear-icon .mx-icon-clear{
    display:initial;
}

</style>