export default class permissionService{

    constructor() {
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        if(this.permissions == null){
            this.permissions=[];
        }
    }
    set(permission) {
        
        this.permissions = permission;
        localStorage.setItem('permissions', JSON.stringify(this.permissions));
    };


    check(permission){
        
        return this.permissions.includes(permission)
    }

   any(...permissions){

        var has_permission = false;

        permissions.forEach(permission => {
            if(this.permissions.includes(permission)){
                has_permission = true;
                return;
            }
        });

        return has_permission;
   }

   all(...permissions){

        var has_permission = true
        permissions.forEach(permission => {
            if(!this.permissions.includes(permission)){
                has_permission = false;
                return;
            }
        });

        return has_permission;
    }



   
}