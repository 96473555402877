import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import router from './routes/router';
import VueMask from 'v-mask';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueSignature from "vue-signature-pad";

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
 


// plugin setup
Vue.use(VueMask);
Vue.use(DashboardPlugin);
Vue.use(PerfectScrollbar);
Vue.use(VueSignature);

Vue.use({
  install: function(Vue, options) {
      Object.defineProperty(Vue.prototype, "uniqId", {
          get: function uniqId() {
              if ('_uid' in this) {
                 return this._uid;
              }
              throw new Error("_uid property does not exist");
          }
      });
  }
});


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
});
