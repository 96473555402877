import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'history',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

let refresh_token_expiries_time = null;

router.beforeEach((to, from, next) => {
  //to.fullPath.replace('%2F', '/')

  if(refresh_token_expiries_time == null){
    refresh_token_expiries_time = localStorage.getItem('refresh_token_expiries_time');
  }

  let currentTime = new Date().getTime() / 1000;
  
  if((refresh_token_expiries_time - currentTime < 0) && to.name !== 'Login'){
    //next({ name: 'Login' , query: { redirect: to.fullPath } })
  } 

  const isAuthenticated = localStorage.getItem('user-portal');
  const userdata = JSON.parse(isAuthenticated);
    if (to.name !== 'Login' && to.name !== 'ForgetPassword' && to.name !== 'ResetPassword' && to.name !== 'Register' ) {
      if(!isAuthenticated) next({ name: 'Login' , query: { redirect: to.fullPath} })
      else next();
    } 
    
  return next();
});

export default router;
