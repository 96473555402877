<template>
    <b-row class="display-flex align-items-center">
           
            <b-col class="pr-0" style="cursor: pointer; outline: none !important; width: calc(100% - 32px);" @click="setSorting">
                <span v-overflow-tooltip :class="{'app-resizable-label' : resizeableColumns}">{{label}}</span>
            </b-col>
            <b-col v-if="sortingObject !== undefined && sortingObject.field_name === fieldName" cols="auto" end class="p-0">                
                <b-icon v-if="this.sortingObject.sort_direction === 1" icon="sort-down" style="cursor: pointer; outline: none !important" @click="setSorting"/>
                <b-icon v-if="this.sortingObject.sort_direction === 2" icon="sort-up"  style="cursor: pointer; outline: none !important" @click="setSorting"/>
            </b-col>
            <b-col v-if="filteringObject !== undefined" cols="auto" end class="p-0"> 
                <app-column-filter-list v-if="filteringObject.type == 'list'" :label="label" :fieldName="fieldName" :filteringObject="filteringObject"/>    
                <app-column-filter-dates v-if="filteringObject.type == 'dates'" :label="label" :fieldName="fieldName" :filteringObject="filteringObject"/>      
           </b-col>
           <b-col v-if="resizeableColumns && fieldName != 'more' && fieldName != 'fw_buttons'" class="app-table-resizeable-header app-table-resizeable-filter" style="height: 21px;">
            </b-col>
    </b-row>     
      
</template>
<script>
import AppColumnFilterDates from './AppColumnFilterDates.vue';
import AppColumnFilterList from './AppColumnFilterList.vue';
export default {

    components: {
        AppColumnFilterList,
        AppColumnFilterDates        
    },
    
    props: ["label", "fieldName", "sortingObject", "filteringObject", "resizeableColumns"],


    data() {
        return {       
           
        };
    },

    methods: {
        
        setSorting() {
            var nSortingObject = this.sortingObject;

            if(this.sortingObject !== undefined && this.sortingObject !== null) {
                
                if (this.sortingObject.field_name !== this.fieldName) {
                     nSortingObject.field_name = this.fieldName;
                     nSortingObject.sort_direction = 2;
                } else {
                    if (this.sortingObject.sort_direction === 1) {
                        nSortingObject.sort_direction = 0;
                    } else {
                        nSortingObject.sort_direction--;
                    } 
                }

                if (this.sortingObject.sort_direction === 0) {
                     nSortingObject.field_name = null;
                }

                nSortingObject.changed ++;
            }       
        },

    },

};
</script>

<style>
.app-spinner-size {
    max-width:20px;
    max-height:20px;
}

.popover {
    min-width: 200px;
}

.popover .arrow {
    display: none !important;
}
.custom-control:focus {
    outline: none !important;
}
</style>
<style scoped>
.app-resizable-label{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: -10px;
    padding-left: 15px;
}
</style>