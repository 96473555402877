<template>
  <div class="wrapper">

    <div class="environment-banner" v-if="envVariables.getEnvironment() == 2">
      <div class="banner-content">
        <span class="banner-text">
          <template v-for="n in 24">
            {{trans('banner-acc-env', 283)}}<template v-if="n < 24"> • </template>
          </template>
        </span>
        <span class="banner-text">
          •
          <template v-for="n in 24">
            {{trans('banner-acc-env', 283)}}<template v-if="n < 24"> • </template>
          </template>
        </span>
      </div>
    </div>
  
    <notifications></notifications>
    <side-bar>
      <template slot="links">
          <sidebar-item :link="{ name: this.trans('dashboard',280), icon: 'ni ni-chart-pie-35', path: '/dashboard'}" />
          <sidebar-item :link="{ name: this.trans('services',280), icon: 'ni ni-calendar-grid-58', path: '/shifts'}" />
          <sidebar-item :link="{ name: this.trans('assignments',280), icon: 'ni ni-calendar-grid-58', path: '/assignments'}" />
          <!-- <sidebar-item :link="{ name: this.trans('hours',280), icon: 'ni ni-check-bold', path: '/hours'}" /> -->
          <sidebar-item v-if="userRole == 5 || (userRole == 6 && userHours == 1)" :link="{ name: 'Declaraties', icon: 'ni ni-time-alarm', path: '/hours'}" />
          <sidebar-item v-if="userRole == 5 || (userRole == 6 && userPlacement == 1)" :link="{ name: this.trans('cp-documents',286), icon: 'ni ni-single-copy-04', path: '/clients/documents'}" />
          <sidebar-item v-if="userRole == 5" :link="{ name: this.trans('users',280), icon: 'ni ni-single-02', path: '/users'}" />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)" style="min-height: calc(100vh - 160px); padding: 0 16px 16px 16px;">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import envVariables from "/src/util/envVariables.js";
  
  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },

    created(){
      this.userPlanning = JSON.parse(localStorage.getItem('user-portal')).planning
      this.userHours = JSON.parse(localStorage.getItem('user-portal')).hours
      this.userPlacement = JSON.parse(localStorage.getItem('user-portal')).placement
      this.userRole = JSON.parse(localStorage.getItem('user-portal')).id_user_const_role
    },

    data(){
      return{
        userRole: null,
        icons: {
                people: require('../../assets/images/svg/menu_people.svg')
        },
        envVariables: new envVariables()
      }
    },

    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
.app-local-line {
    min-width: 80%;
  }
</style>

<style lang="scss" scoped>
.environment-banner {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f5365c;
  color: white;
  text-align: center;
  font-weight: bold;
  z-index: 999;
  overflow: hidden;
  user-select: none;
}

.banner-content {
  display: inline-block;
  white-space: nowrap;
  animation: moveText 120s linear infinite;
  user-select: none;
}

.banner-text {
  display: inline-block;
  white-space: nowrap;
  user-select: none;
}

@keyframes moveText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>