<template>
    <div>
        <b-row class="mb-2 mt-1">
            <b-col cols="12" v-if="onlyWeek == false">
                <b-button-group  size="sm" class="d-flex">
                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': cListType !== 1, 'app-list-button-selected':  cListType === 1}" @click="cListType = 1">Dag</b-btn>
                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': cListType !== 2, 'app-list-button-selected':  cListType === 2 }" @click="cListType = 2">Week</b-btn>
                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': cListType !== 3, 'app-list-button-selected':  cListType === 3 }" @click="cListType = 3">Alle</b-btn>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-input-group class="app-local-input-group" :class="{'app-local-input-group-disabled': cListType == 3}">
                    <b-input-group-prepend v-if="changeArrows" class="app-local-prepend" :class="{'app-local-prepend-filled' : (cListType == 1 && cChoosedDate != null) || (cListType == 2 && cWeekDates != null)}">
                        <b-button variant="transparent" @click="previous" class="app-input-button-group app-icon-button app-local-left-button" :class="{'app-input-button-group-filled': (cListType == 1 && cChoosedDate != null) || (cListType == 2 && cWeekDates != null)}" :disabled="cListType == 3"><b-icon class="custom-icon-color" icon="arrow-left"></b-icon></b-button>
                        <b-button variant="transparent" @click="next" class="app-input-button-group app-icon-button app-local-left-button" style="border-left: none;" :class="{'app-input-button-group-filled': (cListType == 1 && cChoosedDate != null) || (cListType == 2 && cWeekDates != null)}" :disabled="cListType == 3"><b-icon class="custom-icon-color" icon="arrow-right"></b-icon></b-button>
                    </b-input-group-prepend>
                <app-date v-model="cChoosedDate" v-if="local_type == 1" v-bind:class="{'app-local-input': changeArrows}" style="width:78%;" :clearable="clearable" :disabled="cListType == 3" />
                <app-week v-model="cWeekDates" v-if="local_type == 2" v-bind:class="{'app-local-input': changeArrows}" style="width:78%;" :clearable="clearable" :disabled="cListType == 3" />
                </b-input-group>                                  
            </b-col>
        </b-row>
    </div>
</template>
<script>
import moment from "moment";

export default {
    name: 'AppDateWeekSelectorSidebar',

    props: {
       choosedDate: {},
       listType:{type: Number, default: 1},
       weekDates:{type: String, default: ""},
       onlyWeek: {type: Boolean, default: false},
       changeArrows: {type: Boolean, default: false},
       clearable: {type: Boolean, default: false}
    },

    computed: {
        cChoosedDate:{
            get() {return this.choosedDate },
            set(value) { this.$emit('update:choosedDate', value) }
        },

        cListType:{
            get() {return this.listType },
            set(value) { this.$emit('update:listType', value) }
        },

        cWeekDates:{
            get() {return this.weekDates },
            set(value) { this.$emit('update:weekDates', value) }
        },
       
    },

    watch:{
        listType:{
            handler: function(){
                if(this.listType != 3){
                    this.local_type = this.listType
                }
            }
        }
    },
    

    data() {
        return { 
            local_type: 1
        };
    },

    methods:{
        next() {
            if(this.cListType == 1 && this.choosedDate != null){
                var date = moment(this.cChoosedDate, 'DD-MM-YYYY').toDate();
                date = moment(date).add(1, 'd');
                this.cChoosedDate = moment(date).format('DD-MM-YYYY');

            } else if(this.cListType == 2 && this.weekDates != null){
                var date = moment(this.cWeekDates, 'YYYY-W').toDate();
                date = moment(date).add(1, 'w');
                this.cWeekDates = moment(date).format('YYYY-W');
            }
        },
        previous(){
            if(this.cListType == 1 && this.choosedDate != null){
                var date = moment(this.cChoosedDate, 'DD-MM-YYYY').toDate();
                date = moment(date).subtract(1, 'd');
                this.cChoosedDate = moment(date).format('DD-MM-YYYY');

            } else if(this.cListType == 2 && this.weekDates != null){
                var date = moment(this.cWeekDates, 'YYYY-W').toDate();
                date = moment(date).subtract(1, 'w');
                this.cWeekDates = moment(date).format('YYYY-W');
            }
        }
    }

   
};
</script>
<style>

.app-local-input .app-datepicker{
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-left: none !important;
}

.app-local-input .app-datepicker:hover{
    box-shadow: none !important;
    
}

.app-local-input-group{
    transition: none !important;
}

.app-local-input-group:hover{
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) !important;
}

.app-local-input-group .app-input-button-group{
    transition: none !important;
}

.app-local-input-group:hover .app-input-button-group{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;

}

.app-local-input-group:hover .app-datepicker{
    border-color: #066791 !important;
}

.app-local-input-group:focus-within .app-input-button-group{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;
}

.app-input-button-group-filled{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;

}

.app-input-button-group-disabled{
    border-top-color: #066791 !important;
    border-left-color: #066791 !important;
    border-bottom-color: #066791 !important;

}

.app-local-prepend{
    border: 1px solid #D0D5DD;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.app-local-input-group:hover .app-local-prepend, .app-local-input-group:focus-within .app-local-prepend{
    border-color: #066791;
    border-right-color: #D0D5DD !important;
}

.app-local-prepend-filled{
    border-color: #066791;
    border-right-color: #D0D5DD !important;
}

.app-local-left-button{
    border-right: 1px solid #D0D5DD !important;
}

.custom-icon-color{
    color: #066791
}

.app-local-input-group-disabled{
    pointer-events: none !important;
}

</style>

