import moment from "moment";

export const validatorPositive = (value) => {
    if (value >= 0) {
        return true;
    }
    return false;
};

export const validatorPassword = (password) => {
    /* eslint-disable no-useless-escape */
    const regExp = /(?=.*[a-zà-ž])(?=.*[A-ZÀ-Ž])(?=.*[!@#$%&*()\-_<>?:",=+./;\[\]'{}\\]).{8,}/;
    /* eslint-enable no-useless-escape */
    if (password.toUpperCase() == password){
        return false;
    }
    if (password.toLowerCase() == password){
        return false;
    }

    const validPassword = regExp.test(password);
    return validPassword;
};

export const validatorCapitalLetter = (password) => {
    /* eslint-disable no-useless-escape */
    const regExp = /[A-Z]/;
    /* eslint-enable no-useless-escape */
    const validPassword = regExp.test(password);
    return validPassword;
};

export const validatorCreditCard = (creditnum) => {
    /* eslint-disable no-useless-escape */
    const cRegExp = /^(?:3[47][0-9]{13})$/;
    /* eslint-enable no-useless-escape */
    const validCreditCard = cRegExp.test(creditnum);
    return validCreditCard;
};

export const validatorUrlValidator = (val) => {
    if (val === undefined || val === null || val.length === 0) {
        return true;
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    /* eslint-enable no-useless-escape */
    return re.test(val);
};

export const validatorDate = (val) => {
    var date = moment(val).format('DD-MM-YYYY').toString();
    if (date === undefined || date === null || date.length === 0) {
        return true;
    }

    const re = /(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[- /.](19|20)\d\d/;
    /* eslint-enable no-useless-escape */
    return re.test(date);
};

export const validatorTime = (val) => {
    if (val === undefined || val === null || val.length === 0) {
        return true;
    }
    const re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    /* eslint-enable no-useless-escape */
    return re.test(val);
};

export const validatorWeek = (val) => {
    if (val === undefined || val === null || val.length === 0) {
        return true;
    }
    const re = /^([0-9]|[0-3][0-9]|[4][0])$/;
    /* eslint-enable no-useless-escape */
    return re.test(val);
};

export const validatorInternationalPhonePrefix = (val) => {
    if (val === undefined || val === null || val.length === 0) {
        return true;
    }

    const re = /(999|998|997|996|995|994|993|992|991|990|979|978|977|976|975|974|973|972|971|970|969|968|967|966|965|964|963|962|961|960|899|898|897|896|895|894|893|892|891|890|889|888|887|886|885|884|883|882|881|880|879|878|877|876|875|874|873|872|871|870|859|858|857|856|855|854|853|852|851|850|839|838|837|836|835|834|833|832|831|830|809|808|807|806|805|804|803|802|801|800|699|698|697|696|695|694|693|692|691|690|689|688|687|686|685|684|683|682|681|680|679|678|677|676|675|674|673|672|671|670|599|598|597|596|595|594|593|592|591|590|509|508|507|506|505|504|503|502|501|500|429|428|427|426|425|424|423|422|421|420|389|388|387|386|385|384|383|382|381|380|379|378|377|376|375|374|373|372|371|370|359|358|357|356|355|354|353|352|351|350|299|298|297|296|295|294|293|292|291|290|289|288|287|286|285|284|283|282|281|280|269|268|267|266|265|264|263|262|261|260|259|258|257|256|255|254|253|252|251|250|249|248|247|246|245|244|243|242|241|240|239|238|237|236|235|234|233|232|231|230|229|228|227|226|225|224|223|222|221|220|219|218|217|216|215|214|213|212|211|210|98|95|94|93|92|91|90|86|84|82|81|66|65|64|63|62|61|60|58|57|56|55|54|53|52|51|49|48|47|46|45|44|43|41|40|39|36|34|33|32|31|30|27|20|7|1)/;
    /* eslint-enable no-useless-escape */
    return re.test(val);
};

export const validatorElevenProof = (value) => {
    value = value.toUpperCase().replace(/\s/g, "");
    let rearrange = value.substring(4, value.length) + value.substring(0, 4);
    let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    let alphaMap = {};
    let number = [];
    const codeLengths = {
        AD: 24,
        AE: 23,
        AL: 28,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        LC: 30,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
    };
    const iban = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    if (!code || iban.length !== codeLengths[code[1]]) {
        return false;
    }

    alphabet.forEach((value, index) => {
        alphaMap[value] = index + 10;
    });
    rearrange.split("").forEach((value, index) => {
        number[index] = alphaMap[value] || value;
    });
    if (modulo(number.join("").toString(), 97) === 1) {
        return true;
    } else {
        return false;
    }
};

const modulo = (aNumStr, aDiv) => {
    var tmp = "";
    var i, r;
    for (i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        r = tmp % aDiv;
        tmp = r.toString();
    }
    return tmp / 1;
};

export const validatorSofiNumber = (val) => {
    
    var testnumber = val;
    let result = false;
    if (testnumber.toString().length != 9) {
        return result;
    } else {
        var sum = 0;
        var c = 0;
        for (var i = 0; i <= 7; i++) {
            c = testnumber.toString().substring(i, i + 1);
            sum = sum + (9 - i) * c;
        }
        c = testnumber.toString().substring(8, 9);
        c = c * -1;
        sum = sum + c;
        if (sum % 11 == 0) {
            result = true;
        } else {
            result = false;
        }
    }
    return result;
};

export const validatorCorrectPrice = (val) => {
    const regex = /^\d+(,\d{1,2})?$/g;
    return val.toString().match(regex)
    
};

export const validatorCorrectPriceConversionFactor = (val) => {
    const regex = /^\d+(,\d{1,4})?$/g;
    return val.toString().match(regex)
    
};

export const validatorCorrectNumber = (val) => {
    const regex = /^\d+(,\d{1,20})?$/g;
    return val.toString().match(regex)
    
};

export const validatorBTWNumber = (val) => {
    if (val.toString().length == 14) {
        return true;
    } else {
        return false;
    }
};
export const validatorDateMore = (val,arg) => {

    var date = moment(val).format('DD-MM-YYYY').toString();

    var pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
    var d1 = Date.parse(date.replace(pattern,'$3-$2-$1'));
    var d2 = Date.parse(arg[0].replace(pattern,'$3-$2-$1'));
    if(d2>d1){
        return false;
    }else{
        return true;
    }
    
};

export const validatorNumberMore = (val,arg) => {
    if(parseInt(val)<parseInt(arg[0])){
        return false;
    }else{
        return true;
    }
    
};

export const validatorDateOneMonthPast = (val) => {
    var myDate = moment(val).format('DD-MM-YYYY').toString();;

    var date = new Date();
    date.setDate(date.getDate() - 30);
    var currentDate = date.toISOString().split('T')[0]; 

    if(myDate<currentDate){
        return false;
    }else{
        return true;
    }
    
};

export const validatorDoubelComma = (val,arg) => {
    if(val.search(".") > -1) return false;
    val = val.replace(",", ".");
   if (isNaN(val) || Number(val)) {
       return true;
   } else {
       return false;
   }
};
export const validatorAge = (val) => {
    
    var birthday = val;
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    var myAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    
    if (myAge < 16) {
        return false;
    } else {
        return true;
    }
};

export const validatorDocument = (val) => {
    /*var day = val.substring(0, 2);
    var month = val.substring(3, 5);
    var year = val.substring(6, 10);*/
    var fullDate = moment(val).format('DD-MM-YYYY').toString();

    var currentDate = new Date().toJSON().slice(0, 10);
    if (fullDate > currentDate) {
        return true;
    } else {
        return false;
    }
};

export const validatorDateMoreThanToday = (val) => {
    /*var day = val.substring(0, 2);
    var month = val.substring(3, 5);
    var year = val.substring(6, 10);
    var fullDate = year + "-" + month + "-" + day;*/
    //var currentDate = new Date().toJSON().slice(0, 10);
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (val >= currentDate) {
        return true;
    } else {
        return false;
    }
};

export const validatorMultipleEmails = value => {
    if (typeof value === 'undefined' || value === null || value === '') {
      return true
    }
    return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(value)
};


export const validatorMinValue = (val, arg) => {
    var value = val.toString().replace(",", ".");

    if(parseFloat(value) < parseFloat(arg)){
        return false;
    }else{
        return true;
    }

    
};

export const validatorDisableDateFuture = (val, arg) => {
    var myDate = moment(val);
    var date = moment(new Date()).add(parseInt(arg[0])-1, 'days');
    
    if(myDate.isAfter(date)){
        return false;
    } else{
        return true;
    }
};

export const validatorDisableDatePast = (val, arg) => {
    
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    var date = moment(currentDate).add(-(parseInt(arg[0])-1), 'days');
    var myDate = moment(val);

    if(myDate.isBefore(date)){
        return false;
    }else{
        return true;
    }
};

export const validatorDisableTime = (val, arg) => {
    
    var myTime = moment(val, 'HH:mm');
    var x = moment(arg[0], 'HH:mm');
    var y = moment(arg[1], 'HH:mm');
    
    if(myTime.isBefore(x)){
        return false;
    }
    if(myTime.isAfter(y)){
        return false;
    }

    return true;
};
