<template>
    
        <b-overlay :show="loading" :opacity="0.6">
            <b-row class="app-card-header" v-if="isTopBar" v-bind:class="{ 'mb-2': isTopHead==true }">
                
                <b-col start v-if="isTitle || (!isTitle && !loadingInAccordion)">
                    <div class="app-card-tittle app-local-title-height" v-overflow-tooltip v-if="isTitle">
                        <div v-if="title.length > 0" v-overflow-tooltip> {{title}} <span v-if="!loading && isTitleTotal">({{ items.total }})</span></div>
                        <slot name="view-title"></slot>    
                    </div>
                </b-col>

                <b-col v-for="(column, index) in topFiltersCount" v-bind:key="index" cols="auto" class="app-local-title-height" end>
                    <slot :name='"filter-col-" + (index + 1)'></slot>                                  
                </b-col>

                <b-col class="app-input-applicant-filter-width app-local-title-height pl-0" v-if="isSearch">
                    <b-form-input type="text" v-model="cFilters.search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': filters.search.length > 0}" :placeholder="trans('search',280)" size="sm" />
                </b-col>

                <b-col class=" align-items-right pl-1" v-if="isPeriode">
                    <b-row class="app-local-period">
                        <app-date v-model="cFilters.period_date_from" :placeholder="trans('d-from',287)" validatorRules="date-check" class="mr-3 app-local-period-date" :clearable="true"/>
                        <app-date v-model="cFilters.period_date_to" :placeholder="trans('d-to',287)" :validatorRules="'date-check|date-more-than:'+cFilters.period_date_from" :disabledDatesRange="cFilters.disabledDatesRange" class="app-local-period-date" :clearable="true"/>
                    </b-row>
                </b-col>

                <b-col class="app-input-employee-filter-width app-local-title-height pl-0" end v-if="isFilterOptions">
                    <app-select v-model="cFilters.list_type" reduceValue customBody :clearable="false" :selectOnTab="true" :options="cFilterOptions" :selectable="(item) => item.value > 0" >
                        <template v-slot:option="option">
                        <hr v-if="option.data.value < 0" class="app-search-modal-separator">    
                        <span v-else>{{ option.data.name }}</span>
                        </template>
                    </app-select>  
                </b-col>

                <b-col cols="auto" end class="d-flex align-items-center app-local-title-height pl-0" v-bind:class="{ 'app-local-is-accordion': loadingInAccordion && !isTitle && topFiltersCount == 0 && !isSearch && !isFilterOptions}" v-if="isSettings" >
                    <app-table-settings :filters.sync="cFilters" :loadingList="loading" :items="items"/>  
                </b-col>
            </b-row>
            <b-row >
                <b-col cols="12">
                     <b-table responsive small :class="customClass" :hover="isRowHover" select-mode="single" :fields="visibleFields" :items="items.list" ref="selectableTable" :selectable="isSelectable" @row-selected="onRowSelected" @row-clicked="onRowClicked" @row-hovered="onRowHovered" show-empty :tbody-tr-class="rowClass">
                        <template #empty>
                            <div class="d-flex justify-content-center align-items-center app-table-no-records">
                                {{trans('no-records-to-display',281)}}
                            </div>
                        </template>   

                        <template #thead-top="data">
                            <slot name="thead-top" v-bind:data="data"></slot>
                        </template>

                         <template v-slot:cell()="data">
                            <span v-overflow-tooltip >{{ data.value}}</span>
                        </template>                  

                        <template #head()="data">
                            <app-column-filter  :resizeableColumns="resizeableColumns" :label="data.label" :fieldName="data.column" :sortingObject="cFilters.sorting" :filteringObject="data.field.filtering ? cFilters.filtering[data.field.filtering_name] : ''" v-if="data.field.sortable != false"/>      
                            <b-row v-else :class="{'flex-nowrap': resizeableColumns}">
                                <b-col>
                                    <span v-overflow-tooltip>{{data.label}}</span>                   
                                </b-col>
                                <b-col v-if="resizeableColumns" class="app-table-resizeable-header">
                                </b-col>
                            </b-row>
                        </template>
                       
                        <template v-slot:[`head(${column})`]="data" v-for="column in customColumnsHeaders">
                            <slot :name="'head_'+column" v-bind:data="data"></slot>
                        </template>

                        <template v-slot:[`cell(${column})`]="data" v-for="column in customColumns">
                            <slot :name="column" v-bind:data="data" v-if="data.item._groupped == undefined || column != fields[0].key"></slot>
                            <slot name="_groupped" v-bind:data="data" v-else>{{data.item._group_name}}</slot>
                        </template>

                        <template #row-details = "row" >
                            <slot name="row-details" v-bind:data="row"></slot>
                        </template>
                        

                        <template #cell(more)="data" v-if="isDropdownMenu">
                            <b-dropdown  variant="link" no-caret right boundary="window" lazy v-if="(canUserEdit || data.item.can_user_edit) && (!('_groupped' in data.item)) && isMenu(data.item)">
                                <template #button-content>
                                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                </template>
                                
                                <slot name="menu-items" v-bind:data="data">                                  
                                    
                                </slot>                                
                            </b-dropdown>
                        </template>
                        
                     </b-table>    
                </b-col>
            </b-row>
            <div :class="{'remove-filters-container' : bottomBarStaticHeight, 'app-local-sticky-buttons': stickyButtons}">
                <b-row class="d-flex align-items-center mt-2" v-if="isBottomBar">
                    <b-col>
                        <slot name="buttons"></slot>                    
                    </b-col>
                    <b-col v-if="removeAll" cols="auto" end>
                        <app-column-filter-remove-all :filters.sync="cFilters"/>
                    </b-col>
                    
                    <b-col cols="auto" end v-if="items.total > filters.per_page">                        
                        <b-pagination first-number last-number pills align="right" v-model="cFilters.page" :total-rows="items.total" :per-page="cFilters.per_page" size="sm"/>
                    </b-col>
                    <b-col v-if="items.total > 5 && isPerPageSelect" cols="auto" class="p-0 mr-3 app-local-perpage-min-width">
                        <app-select v-model="cFilters.per_page" :clearable="false" :selectOnTab="true" :options="[5, 10, 20, 50, 100]" @input="perPageChanged"/>             
                    </b-col>
                </b-row>
            </div>
            <template #overlay>
                <div></div>
            </template>

        </b-overlay>

    </template>

<script>

import AppTableSettings from '@core/components/Table/AppTableSettings.vue';
import AppColumnFilterRemoveAll from '@core/components/Table/AppColumnFilterRemoveAll.vue';
import AppColumnFilter from '@core/components/Table/AppColumnFilter.vue';

export default {
    components: {
      AppTableSettings,
      AppColumnFilterRemoveAll,  
      AppColumnFilter
    },

    name: 'AppViewTable',

    computed: {
        cFilters: {
            get() { return this.filters },
            set(value) { this.$emit('update:filters', value) }
        },

        cFilterOptions: {
            get() { return this.filterOptions },
            set(value) { this.$emit('update:filterOptions', value) }
        },
        
        visibleFields() {
            var return_fields = this.fields.filter(field => field.visible);
            if (this.isMenuColumn) {
                return_fields.push({ key: "more", label: "", tdClass: "p-0", visible: true, filtering: false, thStyle: {"width": "0px"}});
            }

            return return_fields; 
        },
        cItems: {
            get(){ return this.items; },
            set(value) { this.$emit('update:items', value); }
        },
        rowClass() {
            return this.rowPointerCursor ? 'app-table-body-tr-pointer-cursor' : '';
        }
    },

    mounted: function () {
        this.setResizeColumns();
    },

    props: {
        title: { type: String },
        isTopBar: { type: Boolean, default: true},
        isTopHead: { type: Boolean, default: false},
        isBottomBar: { type: Boolean, default: true},
        isSettings: { type: Boolean, default: true},
        isTitleTotal: { type: Boolean, default: false},     
        isTitle: { type: Boolean, default: true},
        isDropdownMenu: { type: Boolean, default: true},
        isRowHover: { type: Boolean, default: true},
        isSelectable: { type: Boolean, default: true},
        isPeriode: { type: Boolean, default: false},
        isFilterOptions: { type: Boolean, default: false},
        isSearch: { type: Boolean, default: false},
        isMenuColumn: { type: Boolean, default: true},
        isPerPageSelect: { type: Boolean, default: true },
        loading: { type: Boolean, default: false},
        loadingInAccordion: {type: Boolean, default: false},
        custom: { type: Boolean, default: false},
        removeAll: {type: Boolean, default: true},
        bottomBarStaticHeight: { type: Boolean, default: false},
        resizeableColumns: { type: Boolean, default: false},
        resizeMinWidth: { type: Boolean, default: false},
        topFiltersCount: {
                type: Number, 
                default() { return 0; }
            },
        filters: {},
        items: {},
        fields: {},
        filterOptions: {},
        customColumns: {type: Array, default: () => []},
        customClass:{type: String, default:""},
        customColumnsHeaders: {type: Array, default: () => []},
        canUserEdit: {type: Boolean, default: true},
        groupped: {type: Boolean, default: false},
        stickyButtons: {type: Boolean, default: false},
        menuCondition: {type: String, default: "true"},
        rowPointerCursor: { type: Boolean, default: false },
    },

    watch:{
        "items.list": {
            handler: function(val){
                if(!this.grouping){
                    this.groupItems();
                } 
            },
            deep: true,
        }
    },

    data(){
        return{
            grouping: false,
        }
    },

    methods: {
        onRowSelected(event) {
        this.$emit('onRowSelected', event);
        },
        onRowClicked(event) {
        this.$emit('onRowClicked', event);
        },
        onRowHovered(event) {
            this.$emit('onRowHovered', event);
        },

        perPageChanged() {
            this.cFilters.changed++;
        },

        isMenu(item){
            return eval(this.menuCondition);
        },

        async groupItems(){
            if(this.groupped && this.filters.group_by != null && this.filters.group_by != ''){
                this.grouping = true;

                var group_name = this.fields.filter(item => item.key == this.filters.group_by)[0].label;
                var cell_key = this.fields[0].key 
                
                var group_item = this.cItems.list[0][this.filters.group_by];
                
                if(group_item != undefined){
                    var n = this.cItems.list.length;
                    for(var i=0; i<n; i++){
                        if(group_item != this.cItems.list[i][this.filters.group_by]){
                            group_item = this.cItems.list[i][this.filters.group_by];
                            this.cItems.list.splice(i,0,{_rowVariant: 'light', _groupped: true, [cell_key]: group_item, _group_name: group_item});
                            n++;
                            i++;
                        }
                    }
                    this.cItems.list.splice(0,0,{_rowVariant: 'light', _groupped: true, _group_name: this.cItems.list[0][this.filters.group_by], [cell_key]: this.cItems.list[0][this.filters.group_by]});
                }
            }
            await this.$nextTick();
            this.grouping = false;
            
        },

        setResizeColumns(){
                if(this.resizeableColumns == true){
                var thElm;
                var startOffset;

                Array.prototype.forEach.call(
                    document.querySelectorAll("table th"),
                    function (th) {
                        //th.style.position = 'relative !important';
                        var grip = th.getElementsByClassName("app-table-resizeable-header")[0];
                        if(grip){
                            grip.className = 'app-table-resizeable'
                            grip.style.right = 0;
                            grip.style.bottom = 0;
                            grip.style.cursor = 'col-resize';
                            grip.addEventListener('mousedown', function (e) {
                                thElm = th;
                                startOffset = th.offsetWidth - e.pageX;
                            });
                        }
                            //th.appendChild(grip);
                        
                });

                if(this.resizeMinWidth == true){
                    document.addEventListener('mousemove', function (e) {
                        if (thElm) {
                            thElm.style.minWidth = startOffset + e.pageX + 'px';
                            thElm.style.width = startOffset + e.pageX + 'px';
                        }
                    });
                }else{
                    document.addEventListener('mousemove', function (e) {
                        if (thElm) {
                            thElm.style.width = startOffset + e.pageX + 'px';
                        }
                    });
                }

                document.addEventListener('mouseup', function () {
                    thElm = undefined;
                });
            }
        }
        
    },
}
</script>
<style scoped>
.app-local-max-height {
    max-height: 30px;
}

.app-local-perpage-min-width {
    min-width: 80px;
}

.app-local-table-min-height {
    min-height: 150px;
}


.app-local-title-height{
    min-height: 30px;
}

.app-local-is-accordion{
    position: absolute;
    top: -35px;
    right: 25px;
}

.remove-filters-container{
    min-height: 32.4px;
}

.app-local-period{
    float: right;
    margin-right: 2px;
    flex-wrap: nowrap
}

.app-local-period-date{
    min-width:100px
}

.app-local-sticky-buttons{
    position: sticky;
    padding-top: 8px;
    padding-bottom: 8px;
    bottom: 0;
    background-color: white;
    z-index: 100; 
}
</style>
<style>
    .table-light{
        background-color: #fff !important;
        cursor: default;
        pointer-events: none;
        font-weight: 600;
    }

    .table-light > td {
        overflow: initial !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .table-white > td{
        background-color: #edf7fb !important;
    }

    .app-table-resizeable{
        display: flex !important;
        border-right: 1px solid #e9ecef;
        padding-left: 4px;
        padding-right: 4px;
    }

    .app-table-body-tr-pointer-cursor {
        cursor: pointer !important; 
    }
</style>